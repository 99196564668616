import React, { useState, useRef, useEffect } from 'react';

const FadeIn = ({ className, children }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();
  const observerOptions = {
    rootMargin: '0px 0px -50px 0px',
  };
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    }, observerOptions);
    domRef.current && observer.observe(domRef.current);
    return () => domRef.current && observer.unobserve(domRef.current);
  }, [domRef]);
  return (
    <div
      className={`${className} fade-in ${isVisible ? 'reveal' : ''}`}
      ref={domRef}
    >
      {children}
    </div>
  );
};

export default FadeIn;
