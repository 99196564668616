import styled from 'styled-components';
import Container from '../../layout/Container';
import media from '../../layout/mediaQuery';

export const AboutUs2 = styled.div`
  padding: 10rem 0;
  background-color: ${({ bgColor }) =>
    bgColor ? 'var(--color-bg-light)' : 'var(--color-white)'};
  ${({ reverseColumn }) =>
    reverseColumn &&
    `${Container} {
      > * + * {
        order: -1;
    margin-right: 4rem;
  }
    }`};
`;

export const ContainerWrapper = styled(Container)`
  > * {
    width: calc(100% / 2 - 2rem);
  }

  > * + * {
    margin: 0 0 0 4rem;
  }

  ${media.tabLand`
    flex-direction: column;

    > * {
      width: 100%;
    }

    > * + * {
      margin: 4rem 0 0 0;
    }
  `}
`;

export const Left = styled.div`
  > * + * {
    margin: 2rem 0 0 0;
  }
`;

export const Right = styled.div``;
