import styled from 'styled-components';

export const Accordion = styled.div``;

export const Item = styled.div`
  background-color: var(--color-bg-light);
  margin-bottom: 2.5rem;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 2rem;
  /* margin-bottom: 1rem; */
  background-color: var(--color-bg-light);

  &:hover {
    background-color: var(--color-bg-dark);
  }

  span {
    font-size: 1.4rem;
    color: var(--color-primary);
    flex: 0 0 7rem;
  }
`;

export const Content = styled.div`
  overflow: hidden;
  padding: 0 2rem;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-1);
  padding-left: 4rem;
  transition: transform 1s cubic-bezier(0, 1, 0, 1);
  transform-origin: top;

  ul {
    list-style: square;
  }

  > * {
    :last-child {
      margin-bottom: 2rem;
    }

    :first-child {
      margin-top: 2rem;
    }
  }

  &.hide {
    max-height: 0;
    transform: scaleY(0);
  }

  &.show {
    height: auto;
    max-height: 9999px;
    transform: scaleY(1);
  }
`;
