import styled from 'styled-components';
import media from '../../layout/mediaQuery';

export const Heading = styled.div`
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  margin: 0 auto;

  h1 {
    color: ${({ inverseColor }) =>
      inverseColor ? 'var(--color-grey-2)' : 'var(--color-secondary)'};
    padding-left: 1.5rem;
    line-height: 1.3;
    border-left: ${({ center }) =>
      center ? 'unset' : 'solid 0.5rem var(--color-accent)'};
  }

  h4 {
    color: ${({ inverseColor }) =>
      inverseColor ? 'var(--color-grey-2)' : 'var(--color-primary)'};
    font-size: 1.6rem;
    font-weight: 400;
    text-transform: uppercase;
  }

  > * + * {
    margin-top: 1.5rem;
  }

  /* ${media.tabPort`
    margin: 0 auto;
    text-align: center;

    h1 {
      border: unset;
    }
  `} */
`;
