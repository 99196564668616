import styled from 'styled-components';
import media from '../../layout/mediaQuery';

export const WhyAfrica = styled.div`
  padding: 8rem 0;

  li {
    list-style: inherit;
  }

  .container {
    flex-direction: column;
    > * + * {
      margin-top: 4rem;
    }

    .contents {
      display: flex;

      > * {
        &:first-child {
          width: 40%;
        }
        &:last-child {
          width: 60%;
          margin: 0 0 0 4rem;
        }
      }
      .gatsby-img {
        display: block;
        margin: 0 auto;
        padding-bottom: 400px;
      }
    }

    ${media.tabPort`
          .contents {
            flex-direction: column;
            > * {
              &:first-child, &:last-child  {
                width: 100%;
              }
              &:last-child {
                margin: 4rem 0 0 0;
                
              }
            }
            .gatsby-img {
              padding-bottom: 200px;
            }
          }
    `}
  }
`;

// export const ContainerWrapper = styled(Container)``;

export const Contents = styled.div``;

export const Description = styled.p``;
