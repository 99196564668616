import React, { useState } from 'react';
import * as S from './accordion.styled';

const Accordion = ({ className, data }) => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    selected === i && setSelected(null);
    setSelected(i);
  };

  return (
    <S.Accordion className={className}>
      {data.map((item, i) => {
        return (
          <S.Item>
            <S.Title onClick={() => toggle(i)}>
              <h3>{item.title}</h3>
              <span>{selected === i ? '' : 'Read More'}</span>
            </S.Title>
            <S.Content className={selected === i ? 'show' : 'hide'}>
              <ul>
                {item.descriptions.length > 0 &&
                  item.descriptions.map((item) => {
                    return <li>{item.text}</li>;
                  })}
              </ul>
            </S.Content>
          </S.Item>
        );
      })}
    </S.Accordion>
  );
};

export default Accordion;
