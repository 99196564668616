import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as S from './aboutUs2.styled';
import Heading from '../../elements/Heading/Heading.component';
import FadeIn from '../../utils/FadeIn';

const AboutUs2 = ({ data, bgColor, reverseColumn }) => {
  const { tagline, heading, description, image1, paragraph1, paragraph2 } =
    data;
  const img = getImage(image1.image);
  return (
    <S.AboutUs2 reverseColumn={reverseColumn} bgColor={bgColor}>
      <S.ContainerWrapper>
        <S.Left className="u-max-width-70">
          <FadeIn>
            <Heading
              tagline={tagline}
              title={heading}
              description={description}
            />
          </FadeIn>
          {paragraph1 && (
            <FadeIn>
              <p>{paragraph1}</p>
            </FadeIn>
          )}
          {paragraph2 && (
            <FadeIn>
              <p>{paragraph2}</p>
            </FadeIn>
          )}
        </S.Left>
        <S.Right className="u-max-width-70">
          <FadeIn>
            <GatsbyImage image={img} alt={image1.imageAlt} />
          </FadeIn>
        </S.Right>
      </S.ContainerWrapper>
    </S.AboutUs2>
  );
};

export default AboutUs2;
