import React from 'react';
import * as S from './heading.styled';

const Heading = ({
  className,
  tagline,
  title,
  description,
  center,
  inverseColor,
}) => {
  return (
    <S.Heading
      center={center}
      className={className}
      inverseColor={inverseColor}
    >
      <h4>{tagline}</h4>
      <h1>{title}</h1>
    </S.Heading>
  );
};

export default Heading;
