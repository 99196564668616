import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as S from './whyAfrica.styled';
import Heading from '../../elements/Heading/Heading.component';
import Accordion from '../../elements/Accordion/Accordion.component';
import Container from '../../layout/Container';
import FadeIn from '../../utils/FadeIn';

const WhyAfrica = ({ data }) => {
  const { tagline, heading, image1, subHeadings } = data;
  const img = getImage(image1.image);

  return (
    <S.WhyAfrica>
      <Container className="container">
        <FadeIn>
          <Heading
            className="u-max-width-70"
            tagline={tagline}
            title={heading}
            center
          />
        </FadeIn>
        <div className="contents">
          <FadeIn>
            <GatsbyImage
              image={img}
              alt={image1.imageAlt}
              className="gatsby-img u-max-width-70"
            />
          </FadeIn>
          <FadeIn>
            <Accordion className="u-max-width-70" data={subHeadings} />
          </FadeIn>
        </div>
      </Container>
    </S.WhyAfrica>
  );
};

export default WhyAfrica;
