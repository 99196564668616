import React from 'react';
import * as S from './heroInner.styled';
import BgImage from '../../elements/BgImage/BgImage.component';

const HeroInner = ({ image, text }) => {
  return (
    <S.HeroInner>
      <BgImage image={image} height="250px">
        <h1>{text}</h1>
      </BgImage>
    </S.HeroInner>
  );
};

export default HeroInner;
