import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import * as S from './bgImage.styled';

const BgImage = ({ className, children, image, height, mobileHeight }) => {
  const bgImage = getImage(image);
  return (
    <S.BgImage className={className}>
      <S.Image
        image={bgImage}
        height={height}
        mobileHeight={mobileHeight}
        loading="eager"
      />
      {children}
    </S.BgImage>
  );
};

export default BgImage;
