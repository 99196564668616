import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout/Layout';
import HeroInner from '../components/sections/HeroInner/HeroInner.component';
import AboutUs2 from '../components/sections/AboutUs2/AboutUs2.component';
import WhyAfrica from '../components/sections/WhyAfrica/WhyAfrica.component';

const WhyAfricaPageTemplate = ({ data }) => {
  const { title, image, aboutUs, whyAfrica, seo } =
    data.markdownRemark.frontmatter;
  const { seoTitle, seoDescription, browserTitle } = seo;
  return (
    <Layout>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <HeroInner image={image} text={title} />
      <AboutUs2 data={aboutUs} bgColor />
      <WhyAfrica data={whyAfrica} />
    </Layout>
  );
};

WhyAfricaPageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WhyAfricaPageTemplate;

export const pageQuery = graphql`
  query WhyAfricaPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "why-africa-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        aboutUs {
          tagline
          heading
          description
          paragraph1
          paragraph2
          image1 {
            imageAlt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        whyAfrica {
          tagline
          heading
          description
          image1 {
            image {
              childImageSharp {
                gatsbyImageData(transformOptions: { cropFocus: CENTER })
              }
            }
            imageAlt
          }
          subHeadings {
            title
            descriptions {
              text
            }
          }
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
  }
`;
