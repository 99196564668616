import styled from 'styled-components';

export const HeroInner = styled.div`
  h1 {
    animation: moveInBottom 1s ease-out;
    color: var(--color-grey-3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8%;
  }
`;
