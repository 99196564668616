import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import media from '../../layout/mediaQuery';

export const BgImage = styled.div`
  position: relative;
  > * + * {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    z-index: 1;
  }
`;

export const Image = styled(GatsbyImage)`
  height: ${({ height }) => height || 'auto'};
  z-index: 0;

  ${media.tabPort`
  height: ${({ mobileHeight, height }) =>
    mobileHeight ? mobileHeight : height};
`}
`;
